import imageCompression from 'browser-image-compression';
import html2canvas from 'html2canvas';
import moment from 'jalali-moment';
import { getQueryStringsFromLocation, openNewWindow } from 'spec';
import { readFile } from 'xlsx';
import { formatMoneyAmount } from '../components/AryNumberFormatter';
// ***********************************************************************
//
// xlsx formatters
//
// ***********************************************************************

export const customBankFormatterSettings = {
  row: {
    columns: ['I'],
    parse: ([a]) => a
  },
  date: {
    columns: ['G', 'F'],
    parse: ([date, time]) => {
      if (typeof time !== 'string') console.info(time);
      const clearDate = date.trim();
      const clearTime = time.trim();
      const dateAndTime = moment.from(
        `${clearDate} ${clearTime}`,
        'fa',
        'YYYY/M/D HH:mm:ss'
      );
      return new Date(+dateAndTime).getTime().toString();
    }
  },
  branchCode: {
    columns: ['H'],
    // parse: ([branchCode]) => branchCode.trim()
    parse: ([branchCode]) => {
      const branchCodeType = typeof branchCode;
      let parsedBranchCode = '';

      switch (branchCodeType) {
        case 'string':
          parsedBranchCode = branchCode.trim();
          break;
        case 'number':
          parsedBranchCode = branchCode.toString().trim();
          break;
        default:
          break;
      }

      return parsedBranchCode;
    }
  },
  transactionType: {
    columns: ['E'],
    parse: ([transactionType]) => transactionType.trim()
  },
  receiptNumber: {
    columns: ['E'],
    // parse: ([receiptNumber]) => receiptNumber.trim().replace(/ /g, '')
    // parse: ([receiptNumber]) => receiptNumber.trim()
    parse: ([receiptNumber]) => {
      const receiptNumberType = typeof receiptNumber;
      let parsedReceiptNumber = '';

      switch (receiptNumberType) {
        case 'string':
          parsedReceiptNumber = receiptNumber.trim().replace(/ /g, '');
          break;
        case 'number':
          parsedReceiptNumber = receiptNumber.toString().trim();
          break;
        default:
          break;
      }

      return parsedReceiptNumber;
    }
  },
  description: {
    columns: ['D'],
    parse: ([description]) => description.trim()
  },
  notes: {
    columns: ['D'],
    parse: ([description]) => `${description.trim()}`
  },
  deposit: {
    columns: ['B'],
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      switch (depositType) {
        case 'string':
          deposit.trim().replace(/ /g, '');
          break;
        case 'number':
          deposit.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  withdraw: {
    columns: ['C'],
    // parse: ([withdraw]) => +withdraw.replace(/,/g, '').trim()
    parse: ([withdraw]) => {
      const withdrawType = typeof withdraw;
      switch (withdrawType) {
        case 'string':
          withdraw.trim().replace(/ /g, '');
          break;
        case 'number':
          withdraw.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  amount: {
    columns: ['B', 'C'],
    // parse: ([deposit, withdraw]) =>
    //   +deposit.replace(/,/g, '').trim() !== 0
    //     ? +deposit.replace(/,/g, '').trim()
    //     : +withdraw.replace(/,/g, '').trim()

    parse: ([deposit, withdraw]) => {
      const depositType = typeof deposit;
      const withdrawType = typeof withdraw;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          break;
        case 'number':
          deposit = deposit.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      switch (withdrawType) {
        case 'string':
          withdraw = withdraw.replace(/,/g, '').trim();
          break;
        case 'number':
          withdraw = withdraw.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      return deposit !== 0 && deposit !== '0' ? deposit : withdraw;
    }
  },
  isDeposit: {
    columns: ['B'],
    // parse: ([deposit]) => !!+deposit.replace(/,/g, '').trim()
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      let isDeposit;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          isDeposit = deposit !== '' && deposit !== '0';
          break;
        case 'number':
          // deposit = deposit.toString().trim();
          isDeposit = deposit !== 0;
          break;
        default:
          isDeposit = false;
          break;
      }

      return isDeposit;
    }
  },
  balance: {
    columns: ['A'],
    parse: ([balance]) => {
      const balanceType = typeof balance;
      switch (balanceType) {
        case 'string':
          balance.trim().replace(/ /g, '');
          break;
        case 'number':
          balance.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  }
};

export const saderatFormatterSettings = {
  row: {
    columns: ['A'],
    parse: ([a]) => a
  },
  date: {
    columns: ['B', 'C'],
    parse: ([date, time]) => {
      if (typeof time !== 'string') console.info(time);
      const clearDate = date.trim();
      const clearTime = time.trim();
      const dateAndTime = moment.from(
        `${clearDate} ${clearTime}`,
        'fa',
        'YYYY/M/D HH:mm:ss'
      );
      return new Date(+dateAndTime).getTime().toString();
    }
  },
  branchCode: {
    columns: ['D'],
    // parse: ([branchCode]) => branchCode.trim()
    parse: ([branchCode]) => {
      const branchCodeType = typeof branchCode;
      let parsedBranchCode = '';

      switch (branchCodeType) {
        case 'string':
          parsedBranchCode = branchCode.trim();
          break;
        case 'number':
          parsedBranchCode = branchCode.toString().trim();
          break;
        default:
          break;
      }

      return parsedBranchCode;
    }
  },
  transactionType: {
    columns: ['E'],
    parse: ([transactionType]) => transactionType.trim()
  },
  receiptNumber: {
    columns: ['F'],
    // parse: ([receiptNumber]) => receiptNumber.trim().replace(/ /g, '')
    // parse: ([receiptNumber]) => receiptNumber.trim()
    parse: ([receiptNumber]) => {
      const receiptNumberType = typeof receiptNumber;
      let parsedReceiptNumber = '';

      switch (receiptNumberType) {
        case 'string':
          parsedReceiptNumber = receiptNumber.trim().replace(/ /g, '');
          break;
        case 'number':
          parsedReceiptNumber = receiptNumber.toString().trim();
          break;
        default:
          break;
      }

      return parsedReceiptNumber;
    }
  },
  description: {
    columns: ['G'],
    parse: ([description]) => description.trim()
  },
  notes: {
    columns: ['E', 'G'],
    parse: ([transactionType, description]) =>
      `${transactionType.trim()} - ${description.trim()}`
  },
  deposit: {
    columns: ['H'],
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      switch (depositType) {
        case 'string':
          deposit.trim().replace(/ /g, '');
          break;
        case 'number':
          deposit.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  withdraw: {
    columns: ['I'],
    // parse: ([withdraw]) => +withdraw.replace(/,/g, '').trim()
    parse: ([withdraw]) => {
      const withdrawType = typeof withdraw;
      switch (withdrawType) {
        case 'string':
          withdraw.trim().replace(/ /g, '');
          break;
        case 'number':
          withdraw.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  amount: {
    columns: ['H', 'I'],
    // parse: ([deposit, withdraw]) =>
    //   +deposit.replace(/,/g, '').trim() !== 0
    //     ? +deposit.replace(/,/g, '').trim()
    //     : +withdraw.replace(/,/g, '').trim()

    parse: ([deposit, withdraw]) => {
      const depositType = typeof deposit;
      const withdrawType = typeof withdraw;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          break;
        case 'number':
          deposit = deposit.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      switch (withdrawType) {
        case 'string':
          withdraw = withdraw.replace(/,/g, '').trim();
          break;
        case 'number':
          withdraw = withdraw.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      return deposit !== 0 && deposit !== '0' ? deposit : withdraw;
    }
  },
  isDeposit: {
    columns: ['H'],
    // parse: ([deposit]) => !!+deposit.replace(/,/g, '').trim()
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      let isDeposit;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          isDeposit = deposit !== '' && deposit !== '0';
          break;
        case 'number':
          deposit = deposit.toString().trim();
          isDeposit = deposit !== 0;
          break;
        default:
          isDeposit = false;
          break;
      }

      return isDeposit;
    }
  },
  balance: {
    columns: ['J'],
    parse: ([balance]) => {
      const balanceType = typeof balance;
      switch (balanceType) {
        case 'string':
          balance.trim().replace(/ /g, '');
          break;
        case 'number':
          balance.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  }
};

export const saderatNewFormatterSettings = {
  row: {
    columns: ['A'],
    parse: ([a]) => a
  },
  date: {
    columns: ['B', 'C'],
    parse: ([date, time]) => {
      if (typeof time !== 'string') console.info(time);
      const clearDate = date.trim();
      const clearTime = time.trim();
      const dateAndTime = moment.from(
        `${clearDate} ${clearTime}`,
        'fa',
        'YYYY/M/D HH:mm:ss'
      );
      return new Date(+dateAndTime).getTime().toString();
    }
  },
  branchCode: {
    columns: ['D'],
    // parse: ([branchCode]) => branchCode.trim()
    parse: ([branchCode]) => {
      const branchCodeType = typeof branchCode;
      let parsedBranchCode = '';

      switch (branchCodeType) {
        case 'string':
          parsedBranchCode = branchCode.trim();
          break;
        case 'number':
          parsedBranchCode = branchCode.toString().trim();
          break;
        default:
          break;
      }

      return parsedBranchCode;
    }
  },
  transactionType: {
    columns: ['D'],
    parse: ([transactionType]) => transactionType.trim()
  },
  receiptNumber: {
    columns: ['G'],
    // parse: ([receiptNumber]) => receiptNumber.trim().replace(/ /g, '')
    // parse: ([receiptNumber]) => receiptNumber.trim()
    parse: ([receiptNumber]) => {
      const receiptNumberType = typeof receiptNumber;
      let parsedReceiptNumber = '';

      switch (receiptNumberType) {
        case 'string':
          parsedReceiptNumber = receiptNumber.trim().replace(/ /g, '');
          break;
        case 'number':
          parsedReceiptNumber = receiptNumber.toString().trim();
          break;
        default:
          break;
      }

      return parsedReceiptNumber;
    }
  },
  description: {
    columns: ['K'],
    parse: ([description]) => description.trim()
  },
  notes: {
    columns: ['D', 'K'],
    parse: ([transactionType, description]) =>
      `${transactionType.trim()} - ${description.trim()}`
  },
  deposit: {
    columns: ['I'],
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      switch (depositType) {
        case 'string':
          deposit.trim().replace(/ /g, '');
          break;
        case 'number':
          deposit.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  withdraw: {
    columns: ['H'],
    // parse: ([withdraw]) => +withdraw.replace(/,/g, '').trim()
    parse: ([withdraw]) => {
      const withdrawType = typeof withdraw;
      switch (withdrawType) {
        case 'string':
          withdraw.trim().replace(/ /g, '');
          break;
        case 'number':
          withdraw.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  amount: {
    columns: ['I', 'H'],
    // parse: ([deposit, withdraw]) =>
    //   +deposit.replace(/,/g, '').trim() !== 0
    //     ? +deposit.replace(/,/g, '').trim()
    //     : +withdraw.replace(/,/g, '').trim()

    parse: ([deposit, withdraw]) => {
      const depositType = typeof deposit;
      const withdrawType = typeof withdraw;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          break;
        case 'number':
          deposit = deposit.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      switch (withdrawType) {
        case 'string':
          withdraw = withdraw.replace(/,/g, '').trim();
          break;
        case 'number':
          withdraw = withdraw.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      return deposit !== 0 && deposit !== '0' ? deposit : withdraw;
    }
  },
  isDeposit: {
    columns: ['I'],
    // parse: ([deposit]) => !!+deposit.replace(/,/g, '').trim()
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      let isDeposit;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          isDeposit = deposit !== '' && deposit !== '0';
          break;
        case 'number':
          // deposit = deposit.toString().trim();
          isDeposit = deposit !== 0;
          break;
        default:
          isDeposit = false;
          break;
      }

      return isDeposit;
    }
  },
  balance: {
    columns: ['J'],
    parse: ([balance]) => {
      const balanceType = typeof balance;
      switch (balanceType) {
        case 'string':
          balance.trim().replace(/ /g, '');
          break;
        case 'number':
          balance.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  }
};

export const sepahFormatterSettings = {
  row: {
    columns: ['AG'],
    parse: ([a]) => a
  },
  date: {
    columns: ['AC', 'AA'],
    parse: ([date, time]) => {
      const clearDate =
        typeof date === 'string' ? date.trim() : date.toString().trim();
      const clearTime =
        typeof time === 'string' ? time.trim() : time.toString().trim();
      const dateAndTime = moment.from(
        `${clearDate} ${clearTime}`,
        'fa',
        'YYYY/M/D HH:mm:ss'
      );
      return new Date(+dateAndTime).getTime().toString();
    }
  },
  branchCode: {
    columns: ['AF'],
    // parse: ([branchCode]) => branchCode.trim()
    parse: ([branchCode]) => {
      const branchCodeType = typeof branchCode;
      let parsedBranchCode = '';

      switch (branchCodeType) {
        case 'string':
          parsedBranchCode = branchCode.trim();
          break;
        case 'number':
          parsedBranchCode = branchCode.toString().trim();
          break;
        default:
          break;
      }

      return parsedBranchCode;
    }
  },
  transactionType: {
    columns: ['E'],
    parse: ([transactionType]) => transactionType.trim()
  },
  receiptNumber: {
    columns: ['Y', 'Z'],
    // parse: ([receiptNumber]) => receiptNumber.trim().replace(/ /g, '')
    // parse: ([receiptNumber]) => receiptNumber.trim()
    parse: ([receiptNumber]) => {
      const receiptNumberType = typeof receiptNumber;
      let parsedReceiptNumber = '';

      switch (receiptNumberType) {
        case 'string':
          parsedReceiptNumber = receiptNumber.trim().replace(/ /g, '');
          break;
        case 'number':
          parsedReceiptNumber = receiptNumber.toString().trim();
          break;
        default:
          break;
      }

      return parsedReceiptNumber;
    }
  },
  description: {
    columns: ['H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
    parse: ([description]) => description.trim()
  },
  notes: {
    columns: ['H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'],
    parse: ([description]) => description.trim()
  },
  deposit: {
    columns: ['D', 'E', 'F'],
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      switch (depositType) {
        case 'string':
          deposit.trim().replace(/ /g, '');
          break;
        case 'number':
          deposit.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  withdraw: {
    columns: ['G'],
    // parse: ([withdraw]) => +withdraw.replace(/,/g, '').trim()
    parse: ([withdraw]) => {
      const withdrawType = typeof withdraw;
      switch (withdrawType) {
        case 'string':
          withdraw.trim().replace(/ /g, '');
          break;
        case 'number':
          withdraw.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  },
  amount: {
    columns: ['D', 'G'],

    parse: ([deposit, withdraw]) => {
      const depositType = typeof deposit;
      const withdrawType = typeof withdraw;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          break;
        case 'number':
          deposit = deposit.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      switch (withdrawType) {
        case 'string':
          withdraw = withdraw.replace(/,/g, '').trim();
          break;
        case 'number':
          withdraw = withdraw.toString().replace(/,/g, '').trim();
          break;
        default:
          break;
      }

      return deposit !== 0 && deposit !== '0' ? deposit : withdraw;
    }
  },
  isDeposit: {
    columns: ['D'],
    // parse: ([deposit]) => !!+deposit.replace(/,/g, '').trim()
    parse: ([deposit]) => {
      const depositType = typeof deposit;
      let isDeposit;

      switch (depositType) {
        case 'string':
          deposit = deposit.replace(/,/g, '').trim();
          isDeposit = deposit !== '' && deposit !== '0';
          break;
        case 'number':
          // deposit = deposit.toString().trim();
          isDeposit = deposit !== 0;
          break;
        default:
          isDeposit = false;
          break;
      }

      return isDeposit;
    }
  },
  balance: {
    columns: ['C'],
    parse: ([balance]) => {
      const balanceType = typeof balance;
      switch (balanceType) {
        case 'string':
          balance.trim().replace(/ /g, '');
          break;
        case 'number':
          balance.toString().trim().replace(/ /g, '');
          break;
        default:
          break;
      }
    }
  }
};

let getUserCurrenciesQueryDataList = null;
export const customerListFormatterSetting = {
  row: {
    columns: ['A'],
    parse: ([a]) => a
  },
  accountTitle: {
    columns: ['B'],
    parse: ([accountTitle], rowNumber, errors) => {
      const accountTitleType = typeof accountTitle;
      let parsedAccountTitle = '';

      switch (accountTitleType) {
        case 'string':
          parsedAccountTitle = accountTitle.trim();
          break;
        case 'number':
          parsedAccountTitle = accountTitle.toString().trim();
          break;
        default:
          break;
      }
      if (parsedAccountTitle === '') {
        CustomerListExcelFileErrorGenerator(
          parsedAccountTitle,
          rowNumber,
          'کد حساب',
          errors
        );
      }
      return parsedAccountTitle;
    }
  },
  firstName: {
    columns: ['C'],
    parse: ([firstName], rowNumber, errors) => {
      const FirstNameType = typeof firstName;
      let parsedFirstName = '';

      switch (FirstNameType) {
        case 'string':
          parsedFirstName = firstName.trim();
          break;
        case 'number':
          parsedFirstName = firstName.toString().trim();
          break;
        default:
          break;
      }
      if (parsedFirstName === '') {
        CustomerListExcelFileErrorGenerator(
          parsedFirstName,
          rowNumber,
          'نام',
          errors
        );
      }
      return parsedFirstName;
    }
  },
  lastName: {
    columns: ['D'],
    parse: ([lastName], rowNumber, errors) => {
      const lastNameType = typeof lastName;
      let parsedLastName = '';

      switch (lastNameType) {
        case 'string':
          parsedLastName = lastName.trim();
          break;
        case 'number':
          parsedLastName = lastName.toString().trim();
          break;
        default:
          break;
      }
      if (parsedLastName === '') {
        CustomerListExcelFileErrorGenerator(
          parsedLastName,
          rowNumber,
          'نام خانوادگی',
          errors
        );
      }
      return parsedLastName;
    }
  },
  fatherName: {
    columns: ['E'],
    parse: ([fatherName]) => {
      const fatherNameType = typeof fatherName;
      let parsedFatherName = '';

      switch (fatherNameType) {
        case 'string':
          parsedFatherName = fatherName.trim();
          break;
        case 'number':
          parsedFatherName = fatherName.toString().trim();
          break;
        default:
          parsedFatherName = '';
          break;
      }
      return parsedFatherName;
    }
  },
  phoneNumber: {
    columns: ['F'],
    parse: ([phoneNumber], rowNumber, errors) => {
      const phoneNumberType = typeof phoneNumber;
      let parsedPhoneNumber = '';

      switch (phoneNumberType) {
        case 'string':
          parsedPhoneNumber = phoneNumber.trim();
          break;
        case 'number':
          parsedPhoneNumber = phoneNumber.toString().trim();
          break;
        default:
          parsedPhoneNumber = '';
          break;
      }
      if (
        parsedPhoneNumber.length > 15 ||
        (parsedPhoneNumber.length < 6 && parsedPhoneNumber.length > 0)
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedPhoneNumber,
          rowNumber,
          'شماره تلفن',
          errors
        );
      } else if (
        !/^\d+$/.test(parsedPhoneNumber) &&
        parsedPhoneNumber.length > 0
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedPhoneNumber,
          rowNumber,
          'شماره تلفن',
          errors
        );
      }
      return parsedPhoneNumber;
    }
  },
  countryCode: {
    columns: ['G'],
    parse: ([countryCode], rowNumber, errors) => {
      const countryCodeType = typeof countryCode;
      let parsedCountryCode = '';

      switch (countryCodeType) {
        case 'string':
          parsedCountryCode = countryCode.trim();
          break;
        case 'number':
          parsedCountryCode = countryCode.toString().trim();
          break;
        default:
          parsedCountryCode = '';
          break;
      }
      if (
        parsedCountryCode !== '' &&
        (parsedCountryCode.length > 4 || parsedCountryCode.length < 2)
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedCountryCode,
          rowNumber,
          'کد کشور',
          errors
        );
      }
      return parsedCountryCode;
    }
  },
  amount: {
    columns: ['H'],
    parse: ([amount], rowNumber, errors) => {
      const amountType = typeof amount;
      let parsedAmount = '';

      switch (amountType) {
        case 'string':
          parsedAmount = amount.trim();
          break;
        case 'number':
          parsedAmount = amount.toString().trim();
          break;
        default:
          parsedAmount = '';
          break;
      }
      if (parsedAmount !== '' && !Number(parsedAmount)) {
        CustomerListExcelFileErrorGenerator(
          parsedAmount,
          rowNumber,
          'مبلغ ورودی',
          errors
        );
      }
      return parsedAmount;
    }
  },
  currencyCode: {
    columns: ['I'],
    parse: ([currencyCode], rowNumber, errors) => {
      const currencyCodeType = typeof currencyCode;
      let parsedCurrencyCode = '';

      switch (currencyCodeType) {
        case 'string':
          parsedCurrencyCode = currencyCode.trim();
          break;
        case 'number':
          parsedCurrencyCode = currencyCode.toString().trim();
          break;
        default:
          parsedCurrencyCode = '';
          break;
      }

      const foundCurrency = getUserCurrenciesQueryDataList.find(
        (currency) => currency.title.trim() === parsedCurrencyCode.trim()
      );

      if (!foundCurrency && parsedCurrencyCode !== '') {
        errors.push(
          `ارز "${parsedCurrencyCode}" در ردیف ${
            rowNumber - 13
          } جزو ارزهای شما نیست`
        );
        return {
          code: null,
          title: null
        };
      }

      return {
        code: foundCurrency ? foundCurrency.code : '',
        title: foundCurrency ? foundCurrency.title.trim() : ''
      };
    }
  },
  isDebtor: {
    columns: ['J'],
    parse: ([isDebtor], rowNumber, errors) => {
      const isDebtorType = typeof isDebtor;
      let parsedIsDebtor = '';

      switch (isDebtorType) {
        case 'string':
          parsedIsDebtor = isDebtor.trim();
          break;
        case 'number':
          parsedIsDebtor = isDebtor.toString().trim();
          break;
        default:
          parsedIsDebtor = false;
          break;
      }
      if (
        parsedIsDebtor !== 'بدهکار' &&
        parsedIsDebtor !== 'طلبکار' &&
        parsedIsDebtor !== ''
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedIsDebtor,
          rowNumber,
          'بدهکار/طلبکار',
          errors
        );
      } else if (parsedIsDebtor === 'بدهکار') {
        parsedIsDebtor = true;
      } else if (parsedIsDebtor === 'طلبکار') {
        parsedIsDebtor = false;
      }
      return parsedIsDebtor;
    }
  },
  gender: {
    columns: ['K'],
    parse: ([gender], rowNumber, errors) => {
      const genderType = typeof gender;
      let parsedGender = '';

      switch (genderType) {
        case 'string':
          parsedGender = gender.trim();
          break;
        case 'number':
          parsedGender = gender.toString().trim();
          break;
        default:
          break;
      }
      if (
        parsedGender !== 'مرد' &&
        parsedGender !== 'زن' &&
        parsedGender !== 'نامعلوم'
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedGender,
          rowNumber,
          'جنسیت',
          errors
        );
      } else if (parsedGender === 'مرد') {
        parsedGender = 1;
      } else if (parsedGender === 'زن') {
        parsedGender = 2;
      } else if (parsedGender === 'نامعلوم') {
        parsedGender = 3;
      }
      return parsedGender;
    }
  },
  groupCode: {
    columns: ['L'],
    parse: ([groupCode], rowNumber, errors) => {
      const groupCodeType = typeof groupCode;
      let parsedGroupCode = '';

      switch (groupCodeType) {
        case 'string':
          parsedGroupCode = groupCode.trim();
          break;
        case 'number':
          parsedGroupCode = groupCode.toString().trim();
          break;
        default:
          break;
      }
      if (
        parsedGroupCode !== 'عمومی' &&
        parsedGroupCode !== 'بانکی' &&
        parsedGroupCode !== 'هزینه'
      ) {
        CustomerListExcelFileErrorGenerator(
          parsedGroupCode,
          rowNumber,
          'گروه',
          errors
        );
      } else if (parsedGroupCode === 'عمومی') {
        parsedGroupCode = 0;
      } else if (parsedGroupCode === 'بانکی') {
        parsedGroupCode = 1;
      } else if (parsedGroupCode === 'هزینه') {
        parsedGroupCode = 2;
      }
      return parsedGroupCode;
    }
  }
  // personCategory: {
  //   columns: ['M'],
  //   parse: ([personCategory], rowNumber, errors) => {
  //     const personCategoryType = typeof personCategory;
  //     let parsedPersonCategory = '';

  //     switch (personCategoryType) {
  //       case 'string':
  //         parsedPersonCategory = personCategory.trim();
  //         break;
  //       case 'number':
  //         parsedPersonCategory = personCategory.toString().trim();
  //         break;
  //       default:
  //         break;
  //     }
  //     if (
  //       parsedPersonCategory !== 'مشتری' &&
  //       parsedPersonCategory !== 'گیرنده'
  //     ) {
  //       CustomerListExcelFileErrorGenerator(
  //         parsedPersonCategory,
  //         rowNumber,
  //         'دسته بندی',
  //         errors
  //       );
  //     } else if (parsedPersonCategory === 'مشتری') {
  //       parsedPersonCategory = 1;
  //     } else if (parsedPersonCategory === 'گیرنده') {
  //       parsedPersonCategory = 2;
  //     }
  //     return parsedPersonCategory;
  //   }
  // }
};
// ***********************************************************************
//
// date functions
//
// ***********************************************************************

export function getMoonUtcTime(date) {
  if (date === null) return null;
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    12,
    0,
    0,
    0
  ).toString();
}

export function getTimezoneBasedNightTime(myDate) {
  if (myDate === null) return null;
  const date = new Date(myDate);
  date.setFullYear(myDate.getFullYear());
  date.setMonth(myDate.getMonth());
  date.setDate(myDate.getDate());
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date.getTime().toString();
}

export function getUtcTime(date, seconds) {
  if (date === null) return null;
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    seconds || 0,
    0
  )
    .getTime()
    .toString();
}

export function getTimeZoneFormattedDate(
  date,
  isGregorian,
  timeZone,
  isDigitsLatin
) {
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  const numberingSystem = isDigitsLatin ? 'latn' : null;
  const localeDateString = new Date(+date).toLocaleDateString(locale, {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    numberingSystem
  });
  return localeDateString;
}

export function getTimeZoneFormattedTime(
  date,
  isGregorian = false,
  timeZone = 'Asia/Tehran',
  isDigitsLatin = true
) {
  const formattedDate = getTimeZoneFormattedDate(
    date,
    isGregorian,
    timeZone,
    isDigitsLatin
  );
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  const numberingSystem = isDigitsLatin ? 'latn' : undefined;
  const formattedTime = new Date(+date).toLocaleTimeString(locale, {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    numberingSystem
  });
  return `${formattedDate} - ${formattedTime}`;
}

export function getPastOrFutureYear(yearCount, date = new Date().getTime()) {
  const year = new Date(date).Year;
  const dayCount = year % 4 === 0 ? 366 : 365;
  const dayTimeStamp = 24 * 3600 * 1000;
  return date + yearCount * dayCount * dayTimeStamp;
}

export function getMonthName(date, isGregorian, timeZone) {
  const locale = isGregorian ? 'en-US' : 'fa-IR';
  return new Date(+date).toLocaleTimeString(locale, {
    timeZone,
    month: 'long'
  });
}

// ***********************************************************************
//
// function for convert persian and arabic numbers to english
//
// ***********************************************************************

export function fixNumbers(input) {
  return input;
  // const str = input.toString();
  // let finalStr;
  // const persianNumbers = [
  //   /۰/g,
  //   /۱/g,
  //   /۲/g,
  //   /۳/g,
  //   /۴/g,
  //   /۵/g,
  //   /۶/g,
  //   /۷/g,
  //   /۸/g,
  //   /۹/g
  // ];
  // const arabicNumbers = [
  //   /٠/g,
  //   /١/g,
  //   /٢/g,
  //   /٣/g,
  //   /٤/g,
  //   /٥/g,
  //   /٦/g,
  //   /٧/g,
  //   /٨/g,
  //   /٩/g
  // ];
  // for (let i = 0; i < 10; i++) {
  //   finalStr = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
  // }
  // return finalStr;
}

// ***********************************************************************
//
// file compressor
//
// ***********************************************************************

export async function compressFile(fileOrBlob) {
  try {
    if (
      fileOrBlob.type !== 'application/pdf' &&
      fileOrBlob.type !== 'application/msword' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      fileOrBlob.type !== 'application/vnd.ms-excel' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const compressedBlob = await imageCompression(fileOrBlob, {
        initialQuality: 0.3
      });
      return new File([compressedBlob], compressedBlob.name, {
        type: compressedBlob.type
      });
    }
    return fileOrBlob;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function compressFileForProfile(fileOrBlob, maxSizeInKB = 3) {
  try {
    if (
      fileOrBlob.type !== 'application/pdf' &&
      fileOrBlob.type !== 'application/msword' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      fileOrBlob.type !== 'application/vnd.ms-excel' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const compressedBlob = await imageCompression(fileOrBlob, {
        initialQuality: 0.3,
        maxSizeMB: maxSizeInKB / 1024
      });
      return new File([compressedBlob], compressedBlob.name, {
        type: compressedBlob.type
      });
    }
    return fileOrBlob;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function compressFiles(filesBlob) {
  try {
    const compressedFilesPromises = [];
    for (let i = 0; i < filesBlob.length; i++) {
      compressedFilesPromises.push(compressFile(filesBlob[i]));
    }
    const compressedFileBlobs = await Promise.all(compressedFilesPromises);
    const compressedFiles = compressedFileBlobs.map(
      (blob) => new File([blob], blob.name, { type: blob.type })
    );
    return compressedFiles;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getImageSrc(fileOrBlobOrBase64) {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  if (base64regex.test(fileOrBlobOrBase64)) {
    return `data:image/png;base64,${fileOrBlobOrBase64}`;
  }
  const objectURL = URL.createObjectURL(fileOrBlobOrBase64);
  return objectURL;
}

export function getBlobImage(fileBlob) {
  const objectURL = URL.createObjectURL(fileBlob);
  const myImage = new Image();
  myImage.src = objectURL;
  return myImage;
}

// ***********************************************************************
//
// file functions
//
// ***********************************************************************

export function getFileNameFromFileId(fileId) {
  const splittedArray = fileId.split('_');
  splittedArray.shift();
  return splittedArray.join('_');
}

export function downloadFileInBrowser(blob, fileName) {
  const file = new File([blob], fileName, {
    type: blob.type
  });
  const objectURL = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = objectURL;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// ***********************************************************************
//
// functions for strings
//
// ***********************************************************************

export function limitCharacters(text, maxChar) {
  let newText = text.trim();
  if (newText.length <= maxChar) return newText;

  newText = newText.substr(0, maxChar + 1);
  newText = newText.substr(0, newText.lastIndexOf(' '));
  return `${newText}...`;
}

export function translatePhraseItem(t, phraseItem) {
  if (!phraseItem || !t) return null;
  const finalText = phraseItem.shouldTranslate
    ? t(phraseItem.text.toLowerCase().trim())
    : phraseItem.text;
  return finalText;
}

export function generateTransactionDescription(t, tdArr) {
  let td = '';
  tdArr?.forEach((element) => {
    td = td.concat(translatePhraseItem(t, element));
    td = td.concat(' ');
  });
  return td.trim();
}

// ***********************************************************************
//
// xlsx functions
//
// ***********************************************************************

export function getDataFromExcelFile(file, formatter, primaryCurrency) {
  const workbook = readFile(file);
  const numberOfSheets = workbook.SheetNames.length;
  const transactions = [];
  const errors = [];

  // eslint-disable-next-line consistent-return
  const getExcelFileFirstRow = (worksheet, columnOfRow) => {
    let firstRow;
    try {
      for (const z in worksheet) {
        if (
          z.toString().substring(0, columnOfRow[0].length) === columnOfRow[0]
        ) {
          if (worksheet[z].v === '1' || worksheet[z].v === 1) {
            firstRow = parseInt(z.substring(columnOfRow[0].length));
            return firstRow;
          }
        }
      }
      if (!firstRow) {
        // throw new Error(`The first Row of sheet ${worksheet} is not defined!`);
        errors.push('ردیف اول نمیتواند خالی باشد');
      }
      return null;
    } catch (error) {
      // throw new Error(`Error: ${error}`);
      errors.push(`Error: ${error}`);
    }
  };

  // eslint-disable-next-line consistent-return
  const getExcelFileLastRow = (worksheet, firstRow, columnOfRow) => {
    try {
      let lastRow = 0;
      for (const z in worksheet) {
        if (
          z.toString().substring(0, columnOfRow[0].length) === columnOfRow[0]
        ) {
          lastRow = z.toString().substring(columnOfRow[0].length);
        }
      }
      if (lastRow === 0) {
        // throw new Error(`The last Row of sheet ${worksheet} is not defined!`);
        errors.push('ردیف آخر شناسایی نشد');
      }
      return lastRow;
    } catch (error) {
      // throw new Error(`Error: ${error}`);
      errors.push(`Error: ${error}`);
    }
  };

  for (
    let currentWorksheet = 0;
    currentWorksheet < numberOfSheets;
    currentWorksheet++
  ) {
    const worksheet = workbook.Sheets[workbook.SheetNames[currentWorksheet]];
    const firstRow = getExcelFileFirstRow(worksheet, formatter.row.columns);
    const lastRow = getExcelFileLastRow(
      worksheet,
      firstRow,
      formatter.row.columns
    );

    for (let currentRow = firstRow; currentRow <= lastRow; currentRow++) {
      const firstCell = worksheet[formatter.row.columns[0] + currentRow];
      if (!firstCell || !/^\d/.test(firstCell && firstCell.v)) {
        // Skip the current row if the first cell is not a number
        // eslint-disable-next-line no-continue
        continue;
      }

      const dataKeys = Object.keys(formatter);
      const transaction = {};
      for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        const worksheetResult = formatter[key].columns.map(
          (column) => worksheet[column + currentRow].v
        );
        const value = formatter[key].parse(worksheetResult);
        transaction[key] = value;
      }
      if (primaryCurrency === 'IRT') {
        transaction.amount /= 10;
      }
      delete transaction.row;
      delete transaction.transactionType;
      delete transaction.deposit;
      delete transaction.withdraw;
      delete transaction.balance;
      delete transaction.description;
      transactions.push(transaction);
    }
  }

  return { transactions, errors };
}

export function getDataFromCustomerExcelFile(
  file,
  formatter,
  getUserCurrenciesQueryData
) {
  const workbook = readFile(file);
  const numberOfSheets = workbook.SheetNames.length;
  const customers = [];
  const errors = [];

  // eslint-disable-next-line consistent-return
  const getExcelFileFirstRow = (worksheet, columnOfRow) => {
    let firstRow;
    try {
      for (const z in worksheet) {
        if (
          z.toString().substring(0, columnOfRow[0].length) === columnOfRow[0]
        ) {
          if (worksheet[z].v === '1' || worksheet[z].v === 1) {
            firstRow = parseInt(z.substring(columnOfRow[0].length));
            return firstRow;
          }
        }
      }
      if (!firstRow) {
        errors.push('ردیف اول نمیتواند خالی باشد');
      }
      return null;
    } catch (error) {
      errors.push(`Error: ${error}`);
    }
  };

  // eslint-disable-next-line consistent-return
  const getExcelFileLastRow = (worksheet, firstRow, columnOfRow) => {
    try {
      let lastRow = 0;
      for (const z in worksheet) {
        if (
          z.toString().substring(0, columnOfRow[0].length) === columnOfRow[0]
        ) {
          lastRow = z.toString().substring(columnOfRow[0].length);
        }
      }
      if (lastRow === 0) {
        errors.push('ردیف آخر شناسایی نشد');
      }
      return lastRow;
    } catch (error) {
      errors.push(`Error: ${error}`);
    }
  };

  getUserCurrenciesQueryDataList = getUserCurrenciesQueryData;

  for (
    let currentWorksheet = 0;
    currentWorksheet < numberOfSheets;
    currentWorksheet++
  ) {
    const worksheet = workbook.Sheets[workbook.SheetNames[currentWorksheet]];
    const firstRow = getExcelFileFirstRow(worksheet, formatter.row.columns);
    const lastRow = getExcelFileLastRow(
      worksheet,
      firstRow,
      formatter.row.columns
    );

    for (let currentRow = firstRow; currentRow <= lastRow; currentRow++) {
      const firstCell = worksheet[formatter.row.columns[0] + currentRow];
      if (!firstCell || !firstCell.v || /^\s*$/.test(firstCell.v)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const dataKeys = Object.keys(formatter);
      const customer = {};
      for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        const column = formatter[key].columns[0];
        const currentCell = worksheet[column + currentRow];
        const worksheetResult = currentCell ? currentCell.v : '';
        const value = formatter[key].parse(
          [worksheetResult],
          currentRow,
          errors
        );
        customer[key] = value;
      }
      customers.push(customer);
    }
  }

  for (let i = 0; i < customers.length; i++) {
    for (let j = i + 1; j < customers.length; j++) {
      if (customers[i].accountTitle === customers[j].accountTitle) {
        if (customers[i].firstName !== customers[j].firstName) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در نام دارای مقادیر متفاوت باشند.`
          );
        } else if (customers[i].lastName !== customers[j].lastName) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در نام خانوادگی دارای مقادیر متفاوت باشند.`
          );
        } else if (customers[i].fatherName !== customers[j].fatherName) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در نام پدر دارای مقادیر متفاوت باشند.`
          );
        } else if (customers[i].phoneNumber !== customers[j].phoneNumber) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در شماره تلفن دارای مقادیر متفاوت باشند.`
          );
        } else if (
          customers[i].currencyCode.code === customers[j].currencyCode.code
        ) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در کد ارز دارای مقادیر یکسان باشند.`
          );
        } else if (customers[i].countryCode !== customers[j].countryCode) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در کد کشور دارای مقادیر متفاوت باشند.`
          );
        } else if (customers[i].gender !== customers[j].gender) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در جنسیت دارای مقادیر متفاوت باشند.`
          );
        } else if (customers[i].groupCode !== customers[j].groupCode) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در گروه دارای مقادیر متفاوت باشند.`
          );
        } else if (
          customers[i].personCategory !== customers[j].personCategory
        ) {
          errors.push(
            `سطرهایی با شماره حساب ${customers[i].accountTitle} نمیتوانند در دسته بندی دارای مقادیر متفاوت باشند.`
          );
        }
      } else if (customers[i].accountTitle !== customers[j].accountTitle) {
        if (
          customers[i].phoneNumber !== '' &&
          customers[j].phoneNumber &&
          customers[i].phoneNumber === customers[j].phoneNumber
        ) {
          errors.push(
            `سطرهایی با شماره حساب متفاوت  ${customers[i].accountTitle} و ${customers[j].accountTitle} نمیتوانند دارای شماره تلفن یکسان باشند`
          );
        }
      }
    }
  }

  for (const customer of customers) {
    const invalidCustomer =
      (!customer.currencyCode.code &&
        !customer.amount &&
        !customer.isDebtor?.toString()) ||
      (customer.currencyCode.code &&
        customer.amount &&
        customer.isDebtor?.toString());
    const validCustomerPhoneNumber =
      (customer.phoneNumber && customer.countryCode) ||
      (!customer.phoneNumber && !customer.countryCode);

    if (!invalidCustomer) {
      errors.push(
        'در هر ردیف، ارز-مبلغ-طلبکار/بدهکار یا همگی باید پر باشند یا همگی خالی باشند.'
      );
    } else if (!validCustomerPhoneNumber) {
      errors.push(
        'در هر ردیف، شماره تلفن و کد کشور یا همگی باید پر باشند یا همگی خالی باشند.'
      );
    }
    // const validCustomerCurrency =
    //   (customer.currencyCode &&
    //     customer.amount &&
    //     !!customer.isDebtor?.toString()) ||
    //   (!customer.currencyCode ||
    //     !customer.amount ||
    //     !customer.isDebtor?.toString());
    // const invalidCustomer =
    //   !customer.currencyCode.code ||
    //   !customer.amount ||
    //   !customer.isDebtor?.toString();

    // if (invalidCustomer) {
    //   errors.push(
    //     'در هر ردیف، ارز-مبلغ-طلبکار/بدهکار یا همگی باید پر باشند یا همگی خالی باشند.'
    //   );
    // }
  }

  return { customers, errors };
}
// ***********************************************************************
//
// calculation of source and destination
// currencies according to their dollar rate
//
// ***********************************************************************

export function getSourceToDestRate(
  sourceDollarRate,
  sourceIsDivision,
  destinationDollarRate,
  destinationIsDivision
) {
  const sourceToDollarRate = sourceIsDivision
    ? 1 / sourceDollarRate
    : sourceDollarRate;
  const destinationToDollarRate = destinationIsDivision
    ? 1 / destinationDollarRate
    : destinationDollarRate;
  return sourceToDollarRate / destinationToDollarRate;
}

export function convertCalculationRateToSourceAndDestinationAmounts(
  calculationRate,
  sourceToDestRate
) {
  const sourceAmount = 10 ** calculationRate;
  return { sourceAmount, destinationAmount: sourceToDestRate * sourceAmount };
}

// ***********************************************************************
//
// calculation of tertiary components
//
// ***********************************************************************

export function calculateFinalAmount(
  tradeAmount,
  tradeRate,
  calcRate,
  isDivision,
  initialCalculation = true
) {
  if (isDivision && tradeRate && initialCalculation) {
    return (tradeAmount * 10 ** calcRate) / tradeRate;
  }
  if (!isDivision && calcRate !== undefined && initialCalculation) {
    return (tradeAmount * tradeRate) / 10 ** calcRate;
  }
  return null;
}

export function calculateTradeRate(
  tradeAmount,
  finalAmount,
  calcRate,
  isDivision
) {
  if (isDivision && finalAmount) {
    return (tradeAmount * 10 ** calcRate) / finalAmount;
  }
  if (!isDivision && tradeAmount) {
    return (finalAmount * 10 ** calcRate) / tradeAmount;
  }
  return null;
}

export function calculateTradeAmount(
  tradeRate,
  finalAmount,
  calcRate,
  isDivision
) {
  if (isDivision && calcRate !== undefined) {
    return (finalAmount * tradeRate) / 10 ** calcRate;
  }
  if (!isDivision && tradeRate) {
    return (finalAmount * 10 ** calcRate) / tradeRate;
  }
  return null;
}

export function handleChangeFirstTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision,
  initialCalculation = true
}) {
  if (!lockedLabel) {
    setValues({ [initial.name]: numberValue });
  } else if (lockedLabel === tradeRate.lockedLabel) {
    const resultAmount = calculateFinalAmount(
      numberValue,
      tradeRate.amount,
      calculationRate,
      isDivision,
      initialCalculation
    );
    if (resultAmount < 10000000000000) {
      setValues({
        [initial.name]: numberValue,
        [result.name]: resultAmount || ''
      });
    } else if (resultAmount > 10000000000000) {
      setValues({ [initial.name]: numberValue });
    }
  } else if (lockedLabel === result.lockedLabel) {
    const rateAmount = calculateTradeRate(
      numberValue,
      result.amount,
      calculationRate,
      isDivision
    );
    setValues({
      [initial.name]: numberValue,
      [tradeRate.name]: rateAmount || ''
    });
  }
}

export function handleChangeSecondTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision,
  initialCalculation = true
}) {
  if (!lockedLabel) {
    setValues({ [tradeRate.name]: numberValue });
  } else if (lockedLabel === initial.lockedLabel) {
    const resultAmount = calculateFinalAmount(
      initial.amount,
      numberValue,
      calculationRate,
      isDivision,
      initialCalculation
    );
    if (resultAmount < 10000000000000) {
      setValues({
        [tradeRate.name]: numberValue,
        [result.name]: resultAmount || ''
      });
    } else if (resultAmount > 10000000000000) {
      setValues({ [tradeRate.name]: numberValue });
    }
  } else if (lockedLabel === result.lockedLabel) {
    const initialAmount = calculateTradeAmount(
      numberValue,
      result.amount,
      calculationRate,
      isDivision
    );
    setValues({
      [tradeRate.name]: numberValue,
      [initial.name]: initialAmount || ''
    });
  }
}

export function handleChangeThirdTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision
}) {
  if (!lockedLabel) {
    setValues({ [result.name]: numberValue });
  } else if (
    lockedLabel === initial.lockedLabel ||
    (lockedLabel === result.lockedLabel && initial.amount)
  ) {
    const rateAmount = calculateTradeRate(
      initial.amount,
      numberValue,
      calculationRate,
      isDivision
    );
    setValues({
      [result.name]: numberValue,
      [tradeRate.name]: rateAmount || ''
    });
  } else if (lockedLabel === tradeRate.lockedLabel) {
    const initialAmount = calculateTradeAmount(
      tradeRate.amount,
      numberValue,
      calculationRate,
      isDivision
    );
    setValues({
      [result.name]: numberValue,
      [initial.name]: initialAmount || ''
    });
  }
}

// ***********************************************************************
//
// utility functions
//
// ***********************************************************************

// remove elements that desired fields was empty in components like phoneNumber or accounts
export function clearExtendableArray(array, conditions) {
  if (!array || array.length === 0) return array;
  const newArray = array.slice();

  for (let index = array.length - 1; index >= 0; index--) {
    const element = array[index];
    if (conditions) {
      if (conditions.or?.length) {
        for (const field of conditions.or) {
          if (field.length) {
            if (element[field.name].length === field.is) {
              newArray.splice(index, 1);
              break;
            }
          } else if (element[field.name] === field.is) {
            newArray.splice(index, 1);
            break;
          }
        }
      } else if (conditions.and?.length) {
        for (const field of conditions.and) {
          if (field.length) {
            if (element[field.name].length !== field.is) {
              break;
            }
          } else if (element[field.name] !== field.is) {
            break;
          }
          if (field === conditions.and[conditions.and.length - 1]) {
            newArray.splice(index, 1);
          }
        }
      }
    } else if (!element && element !== 0) {
      newArray.splice(index, 1);
    }
  }
  return newArray;
}

export function formatQueryString(queryStringsList) {
  let formattedData = '';

  if (!queryStringsList?.length) return '';
  const clearQueryStringsList = queryStringsList.filter((obj) => obj.value);
  const formattedQueryStringsList = clearQueryStringsList.map(
    (item) => `${item.key}=${item.value}`
  );

  formattedData = formattedQueryStringsList.join('&');

  return formattedData ? `?${formattedData}` : '';
}

export function getQueryStringVariable(variable) {
  const query = getQueryStringsFromLocation();
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

export function createNewReportTab(path, queryStringsArray = []) {
  if (!path) return;
  const queryStrings = formatQueryString(queryStringsArray);
  window.location.href = `${
    window.paths.rptf || window.paths.rptw
  }${path}${queryStrings}`;
}

export function clearGroupCodesToSend(groupCodes) {
  const ALL_GROUP_CODE = -1;
  if (groupCodes.indexOf(ALL_GROUP_CODE) !== -1) {
    return [];
  }
  return groupCodes;
}

export function parseAccessToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getInfoFromUrl(navItems, url) {
  const pages = navItems.map((navItem) => navItem.pages).flat(1);
  const pagesInfo = pages
    .map((page) =>
      page.children
        ? page.children.map((elem) => ({ href: elem.href, title: elem.title }))
        : { href: page.href, title: page.title }
    )
    .flat(1);
  const desiredPageInfo = pagesInfo.find((page) => page.href === url);
  return desiredPageInfo;
}

export function dataURLtoFile(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function takeScreenShot() {
  const screenshot = document.getElementById('root').firstChild;

  const canvas = await html2canvas(screenshot);
  const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
  const file = dataURLtoFile(dataUrl, 'bugScreenshot.jpg');
  return file;
}

export function assignInitialValue(initialValues, key, defaultValue) {
  return key in initialValues ? initialValues[key] : defaultValue;
}

// ***********************************************************************
//
// transaction description parser
//
// ***********************************************************************

function getConditionalHookResult(text, hookValue) {
  const pureText = text.substr(1, text.length - 2);
  const value = pureText.split('|')[1];
  return value.split('//')[+hookValue];
}

function parseSinglePrefixes(prefixes, hookValue, matchedValue, t) {
  if (prefixes.length === 0) return hookValue;

  let finalText = hookValue;

  // todo: throw exception when exception occurred!

  if (prefixes.includes('!')) {
    finalText = t(finalText);
  }
  if (prefixes.includes('$')) {
    const decimalPlaces = getDecimalPlaces(hookValue);
    finalText = formatMoneyAmount(finalText, decimalPlaces);
  }
  if (prefixes.includes('*')) {
    finalText = getTimeZoneFormattedTime(finalText);
  }
  if (prefixes.includes('@')) {
    finalText = getTimeZoneFormattedDate(finalText);
  }
  if (prefixes.includes('?')) {
    finalText = finalText || t('Unknown');
  }
  if (prefixes.includes('&')) {
    finalText = finalText ? `(${finalText})` : '';
  }
  if (prefixes.includes('%')) {
    finalText = getConditionalHookResult(matchedValue, hookValue);
  }
  return finalText;
}

function getDecimalPlaces(matchedValue) {
  const decimalRegex = /\.\d+/;
  const decimalMatches = matchedValue.match(decimalRegex);
  if (decimalMatches) {
    return decimalMatches[0].length - 1;
  }
  return 0;
}

function getParsedHookValue(matchedValue, hookName, hookValue, t) {
  if (!hookValue) return '';
  const prefixes = matchedValue.substr(1, matchedValue.indexOf(hookName) - 1);
  if (matchedValue.includes('#')) {
    return []
      .concat(hookValue)
      .map((elem) => parseSinglePrefixes(prefixes, elem, matchedValue, t))
      .join(`${t('Comma')} `);
  }
  return parseSinglePrefixes(prefixes, hookValue, matchedValue, t);
}

function checkForRecursiveTemplates(template, metadata, t) {
  const regexStr = '<\\{[^>]*\\}>';
  const regex = new RegExp(regexStr, 'g');
  const matchedArray = template.match(regex);
  if (matchedArray) {
    const recursiveTemplate = matchedArray[0];
    const hookDetectorRegexStr = '\\{[^\\}]+\\}';
    const hookDetectorRegex = new RegExp(hookDetectorRegexStr, 'g');
    const hooksArray = recursiveTemplate
      .substr(2, recursiveTemplate.length - 4)
      .match(hookDetectorRegex)
      .map((hook) => hook.match(/[-a-zA-Z]+/)[0]);
    const desiredMetadata = hooksArray.map((hookName) => ({
      [hookName]: metadata.filter((obj) => obj.hook === hookName)
    }));
    const iterationCount = Object.values(desiredMetadata[0])[0].length;
    const parsedIterations = Array(iterationCount).fill(recursiveTemplate);
    for (let i = 0; i < iterationCount; i++) {
      const currentMetadata = desiredMetadata
        .map((obj) => ({
          [Object.keys(obj)[0]]: Object.values(obj)[0][i]
        }))
        .map((elem) => Object.values(elem)[0]);
      currentMetadata.forEach((hookObj) => {
        const innerRegexStr = `\\{[^(\\{\\}a-zA-Z)]*${hookObj.hook}(\\|[^\\}]*)?\\}`;
        const innerRegex = new RegExp(innerRegexStr, 'g');
        if (parsedIterations[i].match(innerRegex)) {
          const matchedString = parsedIterations[i].match(innerRegex)[0];
          parsedIterations[i] = parsedIterations[i].replace(
            innerRegex,
            getParsedHookValue(matchedString, hookObj.hook, hookObj.value, t)
          );
        } else {
          console.info(`${innerRegex} not found!`);
        }
      });
    }
    const parsedTemplate = parsedIterations
      .map((parsedIteration) =>
        parsedIteration.substr(2, parsedIteration.length - 4)
      )
      .join(`${t('Comma')} `);
    const remainingMetadata = metadata.filter(
      (obj) => hooksArray.indexOf(obj.hook) === -1
    );
    return checkForRecursiveTemplates(
      template.replace(regex, parsedTemplate),
      remainingMetadata
    );
  }
  return [template, metadata];
}

export function parseDescriptionTemplate(templates, t, tId, metadata) {
  if (!templates?.length || !tId) return '';
  const parsedTemplate = templates.find((obj) => obj.id === tId);
  let parsedDescription = parsedTemplate?.body;
  if (!parsedTemplate) return `No related template found with ${tId}`;

  let remainingMetadata = metadata;

  [parsedDescription, remainingMetadata] = checkForRecursiveTemplates(
    parsedDescription,
    metadata,
    t
  );

  const clearMetadata = [];
  remainingMetadata.forEach((hookObj) => {
    const hookIndex = clearMetadata.findIndex(
      (obj) => obj.hook === hookObj.hook
    );
    if (hookIndex === -1) {
      clearMetadata.push({ ...hookObj });
    } else {
      clearMetadata[hookIndex].value = []
        .concat(clearMetadata[hookIndex].value)
        .concat(hookObj.value);
    }
  });

  clearMetadata.forEach((hookObj) => {
    const regexStr = `\\{[^(\\}a-zA-Z)]*${hookObj.hook}(\\|[^\\}]*)?\\}`;
    const regex = new RegExp(regexStr, 'g');
    if (parsedDescription.match(regex)) {
      const matchedString = parsedDescription.match(regex)[0];
      parsedDescription = parsedDescription.replace(
        regex,
        getParsedHookValue(matchedString, hookObj.hook, hookObj.value, t)
      );
    } else {
      console.info(`${regex} not found!`);
    }
  });
  return parsedDescription.trim();
}

export function deepCompare(obj1, obj2) {
  // Check if both objects are null or undefined
  if (obj1 == null || obj2 == null) {
    return obj1 === obj2;
  }

  // Check if both objects are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  // Check if both objects are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  // Check if both objects are primitive types
  return obj1 === obj2;
}

export const convertPersianDigitsToEnglish = (text) => {
  const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  let convertedString = text;
  for (let i = 0; i < persianDigits.length; i++) {
    convertedString = convertedString.replace(
      new RegExp(persianDigits[i], 'g'),
      englishDigits[i]
    );
    convertedString = convertedString.replace(
      new RegExp(arabicDigits[i], 'g'),
      englishDigits[i]
    );
  }

  return convertedString;
};

// user can not type any character instead of number
export const numberTypeAccepter = (character) => {
  const NUMERIC_REGEX = /^[\u0660-\u0669\u06F0-\u06F90-9]+$/;
  let characters;

  if (character === '') {
    characters = '';
  } else if (NUMERIC_REGEX.test(character)) {
    characters = character;
  }

  return characters;
};

export const ShareToWhatsApp = async (sendText, phoneNumber) => {
  let whatsappUrl;

  const encodedText = encodeURIComponent(sendText);

  if (phoneNumber) {
    whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodedText}`;
  } else {
    whatsappUrl = `whatsapp://send?text=${encodedText}`;
  }
  openNewWindow(whatsappUrl);
};

export const CustomerListExcelFileErrorGenerator = async (
  cellError,
  rowNumber,
  columnValue,
  errors
) => {
  const errorMessage = `مقدار "${cellError}" در ردیف "${
    rowNumber - 13
  }" برای ستون "${columnValue}" یک مقدار غیرقابل قبول هست.`;
  errors.push(errorMessage);
};

export const CustomerListExcelFileSameAccountNumberErrorGenerator = async (
  columnTitle,
  accountNumber,
  errors
) => {
  const errorMessage = `کد حساب ${accountNumber} نمیتواند دارای مقادیر متفاوتی در ${columnTitle} باشد`;
  errors.push(errorMessage);
};

export const arrayToJson = (data, opts = ['code', 'title']) => {
  const [key, value] = opts;
  const JsonObj = {};
  if (!data) {
    throw new Error(
      'your incoming object which is going to convert, is null or empty!'
    );
  }
  data?.forEach((item) => {
    JsonObj[item[key]] = item[value];
  });

  return JsonObj;
};

let timeout = null;
export const debounce = (callback, delay = 300) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    callback();
  }, delay);
};

export function hasDuplicatePhoneNumber(phoneNumbers, item) {
  return (
    phoneNumbers.filter(({ phoneNumber }) => phoneNumber === item).length > 1
  );
}

export function navigateNewTab(address, data) {
  const newtab = window.open(address, '_blank');
  newtab.window.onload = () => {
    newtab.history.pushState(data, '', address);
  };
}
