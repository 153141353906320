import { green, grey, indigo, red } from '@mui/material/colors';
import merge from 'deepmerge';
import THEMES from './constants';

const customBlue = {
  50: '#e9f0fb',
  100: '#E6F7FE',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#00ACF0',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 '
};

const customPink = {
  100: '#FDECF5',
  500: '#E742A1'
};

const customRed = {
  100: '#FDEEEE',
  300: '#F8BABA',
  500: '#F83434',
  700: '#E70D0D'
};

const customOrange = {
  100: '#FEF0E2',
  500: '#FE774F',
  700: '#F36035'
};

const customGreen = {
  100: '#E6FAF4',
  300: '#99E9D1',
  500: '#00C98D',
  700: '#00A073'
};

const customGrey = {
  0: '#FFFFFF',
  25: '#F5F7FA',
  50: '#E9EDF5',
  100: '#D1DBE8',
  200: '#B2C2D6',
  300: '#91A4BC',
  350: '#747D8B',
  400: '#7A90AA',
  500: '#647E9A',
  600: '#566E88',
  700: '#465A71',
  800: '#344456'
};

const customPrimary = {
  40: '#EBF4F9',
  60: '#D9EBF4',
  80: '#CCE4F0',
  200: '#80BADB',
  300: '#55A3CD',
  400: '#258AC0',
  500: '#0077B6',
  600: '#005299',
  700: '#003E74',
  800: '#002C58'
};

const customSecondary = {
  20: '#00B8EB',
  40: '#00A4D6'
};

const customTertiary = {
  20: '#00E1FF',
  80: '#0092CC',
  100: '#0075A3'
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: 'light',
    primary: {
      main: customBlue[700],
      contrastText: '#FFF'
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF'
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFF'
    }
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800]
    },
    indicator: {
      background: customBlue[600]
    }
  },
  footer: {
    color: grey[500],
    background: '#FFF'
  },
  sidebar: {
    color: grey[200],
    background: '#233044',
    header: {
      color: grey[200],
      // color: ${(props) => props.theme.navbar.grey.dark};
      background: '#233044',
      brand: {
        color: customBlue[500]
      }
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: customBlue[500]
    }
  }
};

const designerVariant = {
  name: THEMES.DESIGNER,
  palette: {
    mode: 'light',
    primary: {
      main: customPrimary[500],
      contrastText: '#FFF'
    },
    secondary: {
      main: customGrey[350],
      contrastText: customGrey[350]
    },
    error: {
      main: customRed[700],
      contrastText: customRed[700]
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFF'
    },
    white: {
      main: customGrey[0]
    },
    disable: {
      main: customGrey[25],
      switch: '#9ECBE3'
    },
    orange: {
      main: customOrange[700]
    },
    green: {
      main: customGreen[500]
    },
    lightGrey: {
      main: customGrey[300]
    }
  },
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800]
    },
    indicator: {
      background: red[600]
    }
  },
  navbar: {
    grey: {
      light: customGrey[25],
      dark: customGrey[600]
    },
    mobileMenu: {
      backgroundColor: customGrey[800],
      color: customGrey[0]
    }
  },
  input: {
    white: {
      color: customGrey[0]
    }
  },
  alert: {
    color: customGrey[0],
    background: customSecondary[40]
  },
  sidebar: {
    color: customGrey[100],
    whiteColor: customGrey[0],
    background: '#001824',
    blueBackground: customPrimary[700],
    active: {
      darkBg: {
        color: customGrey[50],
        background: customGrey[800]
      },
      blueBg: {
        color: customGrey[0],
        background: customPrimary[800]
      }
    },
    header: {
      color: grey[200],
      background: '#0D242F',
      whiteBackground: customGrey[0],
      drawerIcon: {
        color: customGrey[0],
        backgroundColor: customGrey[600]
      },
      brand: {
        color: red[500]
      },
      profile: {
        color: customGrey[0],
        viewProfileButton: {
          color: customSecondary[20]
        }
      },
      balance: {
        color: customGrey[0]
      },
      divider: {
        backgroundColor: customGrey[700]
      }
    },
    footer: {
      color: grey[200],
      background: '#1E2A38',
      online: {
        background: green[500]
      }
    },
    badge: {
      color: '#FFF',
      background: customRed[500]
    }
  },
  text: {
    primary: {
      color: '#152738'
    },
    primary200: {
      color: customPrimary[200]
    },
    primary300: {
      color: customPrimary[300]
    },
    primary500: {
      color: customPrimary[500]
    },
    primary600: {
      color: customPrimary[600]
    },
    secondary: {
      color: customGrey[350]
    },
    tertiary: {
      color: '#A1ACBF'
    },
    lightGreen: {
      color: customGreen[300]
    },
    green: {
      color: customGreen[500]
    },
    darkGreen: {
      color: customGreen[700]
    },
    lightRed: {
      color: customRed[300]
    },
    red: {
      color: customRed[500]
    },
    darkRed: {
      color: customRed[700]
    },
    blue: {
      color: customBlue[500]
    },
    orange: {
      color: customOrange[500]
    },
    darkGrey: {
      color: customGrey[600]
    },
    grey100: {
      color: customGrey[100]
    },
    grey300: {
      color: customGrey[300]
    },
    grey350: {
      color: customGrey[350]
    },
    grey800: {
      color: customGrey[800]
    },
    pink500: {
      color: customPink[500]
    },
    tertiary80: {
      color: customTertiary[80]
    }
  },
  footer: {
    color: grey[500],
    background: '#FFF'
  },
  chip: {
    red: {
      background: customRed[100],
      text: customRed[500]
    },
    green: {
      background: customGreen[100],
      text: customGreen[500]
    },
    blue: {
      background: customBlue[100],
      text: customPrimary[500]
    },
    orange: {
      background: customOrange[100],
      text: customOrange[500]
    },
    pink: {
      background: customPink[100],
      text: customPink[500]
    },
    grey: {
      background: customGrey[25],
      text: customGrey[350]
    },
    primary: {
      background: customPrimary[80]
    },
    timeReport: {
      background: '#E1EDF5'
    }
  },
  switchableChip: {
    happy: {
      background: customPrimary[200],
      text: customGrey[500]
    },
    unHappy: {
      background: 'trasnparent',
      text: customGrey[500]
    }
  },
  icon: {
    wrappedGreyIcon: {
      color: customGrey[100],
      backgroundColor: '#E5EBF3'
    },
    grey50: {
      color: customGrey[50],
      backgroundColor: '#F9FAFB'
    },
    red: {
      color: customRed[500]
    },
    grey200: {
      color: customGrey[200]
    },
    grey350: {
      color: customGrey[350]
    },
    grey500: {
      color: customGrey[500]
    },
    grey: {
      color: customGrey[300]
    },
    lightgrey: {
      color: customGrey[100]
    },
    search: {
      color: customGrey[200]
    },
    errorPageExclamationSign: {
      color: customSecondary[20]
    },
    disable: {
      color: '#E5EBF3'
    },
    uploader: {
      color: '#0096D6'
    }
  },
  card: {
    border: {
      color: customGrey[100]
    }
  },
  tab: {
    border: {
      color: customGrey[50]
    },
    mobile: {
      backgroundColor: customGrey[0]
    }
  },
  wrapper: {
    background: {
      default: '#F9FAFB',
      paper: '#FFF'
    },
    border: {
      color: customGrey[200]
    },
    blueBackground: {
      color: customPrimary[40]
    },
    blueBorder: {
      color: customPrimary[500]
    },
    uploader: {
      backgroundColor: customGrey[25]
    },
    grey25: {
      backgroundColor: customGrey[25]
    },
    grey50: {
      backgroundColor: customGrey[50]
    },
    grey100: {
      backgroundColor: customGrey[100]
    },
    grey350: {
      backgroundColor: customGrey[350]
    },
    green100: {
      backgroundColor: customGreen[100]
    },
    green500: {
      color: customGreen[500]
    },
    red100: {
      backgroundColor: customRed[100]
    },
    red500: {
      color: customRed[500]
    },
    orange100: {
      backgroundColor: customOrange[100]
    },
    orange500: {
      color: customOrange[100]
    }
  },
  table: {
    header: { color: customGrey[0] },
    background: {
      grey: { color: customGrey[50] }
    },
    splitter: {
      grey: { color: customGrey[100] }
    },
    darkRow: {
      backgroundColor: customGrey[25]
    },
    highlightedRow: { backgroundColor: '#FFFFE0' },
    incomingDraft: {
      color: customTertiary[20]
    },
    outgoingDraft: {
      color: customOrange[500]
    }
  },
  stepper: {
    background: {
      color: customGrey[200]
    },
    label: {
      color: customGrey[200]
    }
  },
  radio: {
    color: '#A1ACBF'
  },
  checkbox: {
    color: '#A1ACBF'
  },
  textField: {
    label: {
      color: '#A1ACBF'
    },
    border: {
      color: customGrey[100]
    }
  },
  select: {
    icon: {
      color: '#A1ACBF'
    },
    selectedOption: {
      backgroundColor: customPrimary[60]
    },
    hoveredOption: {
      backgroundColor: customGrey[25]
    }
  },
  reactSelect: {
    background: {
      color: 'white'
    },
    icon: {
      color: '#A1ACBF'
    },
    placeholder: {
      color: '#A1ACBF'
    },
    border: {
      color: customGrey[100]
    },
    selectedOption: {
      backgroundColor: customPrimary[60]
    },
    hoveredOption: {
      backgroundColor: customGrey[25]
    }
  },
  tooltip: {
    label: {
      color: customGrey[0]
    }
  },
  constants: {
    chequeCategory: {
      SavedToCustomerAccount: {
        color: customBlue[500]
      },
      SavedToCashDeskOrBankAccount: {
        color: customOrange[500]
      },
      HasReturned: {
        color: customRed[500]
      },
      CashedToMoney: {
        color: customGreen[500]
      }
    }
  },
  passwordStrength: {
    none: {
      color: customGrey[100]
    },
    weak: {
      color: customRed[500]
    },
    medium: {
      color: customOrange[500]
    },
    good: {
      color: customGreen[500]
    },
    excellent: {
      color: customGreen[700]
    }
  },
  chart: {
    primary500: customPrimary[500],
    primary600: customPrimary[600],
    secondary40: {
      main: customSecondary[40],
      opacity3: 'rgba(0, 164, 214, 0.3)'
    },
    tertiary20: customTertiary[20],
    tertiary100: customTertiary[100],
    primary400: customPrimary[400],
    grey400: customGrey[400],
    grey600: { main: customGrey[600], opacity2: 'rgba(86, 110, 136, 0.2)' }
  },
  mobile: {
    squareIconButton: {
      color: customPrimary[400],
      backgroundColor: '#E5EBF3'
    },
    table: {
      head: {
        backgroundColor: customGrey[25]
      }
    }
  }
};

const darkVariant = merge(designerVariant, {
  name: THEMES.DARK,
  palette: {
    mode: 'dark',
    primary: {
      main: customBlue[600],
      contrastText: '#FFF'
    },
    background: {
      default: '#1B2635',
      paper: '#233044'
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.95)',
      secondary: 'rgba(255, 255, 255, 0.5)'
    }
  },
  header: {
    color: grey[300],
    background: '#233044',
    search: {
      color: grey[200]
    }
  },
  footer: {
    color: grey[300],
    background: '#233044'
  },
  text: {
    primary: {
      color: customGrey[50]
    },
    primary500: {
      color: customPrimary[200]
    },
    secondary: {
      color: customGrey[300]
    },
    tertiary: {
      color: '#A1ACBF'
    }
  },
  wrapper: {
    background: {
      default: customGrey[800],
      paper: customGrey[700]
    },
    border: {
      color: 'rgba(255, 255, 255, 0.23)'
    },
    blueBackground: {
      color: customGrey[700]
    },
    uploader: {
      backgroundColor: customGrey[700]
    },
    grey25: {
      backgroundColor: customGrey[700]
    },
    grey50: {
      backgroundColor: customGrey[600]
    }
  },
  input: {
    white: {
      color: 'inherit'
    }
  },
  textField: {
    label: {
      color: '#A1ACBF'
    },
    border: {
      color: 'rgba(255, 255, 255, 0.23)'
    }
  },
  select: {
    selectedOption: {
      backgroundColor: 'rgba(64, 122, 214, 0.24)'
    },
    hoveredOption: {
      backgroundColor: 'rgba(64, 122, 214, 0.16)'
    }
  },
  reactSelect: {
    background: {
      color: '#233044'
    },
    border: {
      color: 'rgba(255, 255, 255, 0.23)'
    },
    selectedOption: {
      backgroundColor: 'rgba(64, 122, 214, 0.24)'
    },
    hoveredOption: {
      backgroundColor: 'rgba(64, 122, 214, 0.16)'
    }
  },
  table: {
    header: { color: '#233044' },
    background: {
      grey: { color: customGrey[500] }
    },
    // splitter: {
    //   grey: { color: customGrey[100] }
    // },
    darkRow: {
      backgroundColor: customGrey[800]
    }
    // incomingDraft: {
    //   color: customTertiary[20]
    // },
    // outgoingDraft: {
    //   color: customOrange[500]
    // }
  },
  tab: {
    border: {
      color: customGrey[300]
    },
    mobile: {
      backgroundColor: customGrey[800]
    }
  },
  chip: {
    // red: {
    //   background: customRed[100],
    //   text: customRed[500]
    // },
    // green: {
    //   background: customGreen[100],
    //   text: customGreen[500]
    // },
    blue: {
      background: customBlue[700],
      text: customPrimary[60]
    },
    // orange: {
    //   background: customOrange[100],
    //   text: customOrange[500]
    // },
    // pink: {
    //   background: customPink[100],
    //   text: customPink[500]
    // },
    grey: {
      background: customGrey[600],
      text: customGrey[200]
    },
    primary: {
      background: customPrimary[300]
    },
    timeReport: {
      background: customGrey[800]
    }
  },
  tooltip: {
    label: {
      color: customGrey[500]
    }
  },
  icon: {
    // wrappedGreyIcon: {
    //   color: customGrey[100],
    //   backgroundColor: '#E5EBF3'
    // },
    // grey50: {
    //   color: customGrey[50],
    //   backgroundColor: '#F9FAFB'
    // },
    // red: {
    //   color: customRed[500]
    // },
    // grey200: {
    //   color: customGrey[200]
    // },
    grey350: {
      color: customGrey[300]
    },
    // grey500: {
    //   color: customGrey[500]
    // },
    // grey: {
    //   color: customGrey[300]
    // },
    // lightgrey: {
    //   color: customGrey[100]
    // },
    // search: {
    //   color: customGrey[200]
    // },
    // errorPageExclamationSign: {
    //   color: customSecondary[20]
    // },
    disable: {
      color: customGrey[800]
    }
    // uploader: {
    //   color: '#0096D6'
    // }
  },
  mobile: {
    squareIconButton: {
      color: customPrimary[800],
      backgroundColor: customGrey[400]
    },
    table: {
      head: {
        backgroundColor: customGrey[800]
      }
    }
  }
});

const lightVariant = merge(defaultVariant, {
  name: THEMES.LIGHT,
  palette: {
    mode: 'light'
  },
  header: {
    color: grey[200],
    background: customBlue[800],
    search: {
      color: grey[100]
    },
    indicator: {
      background: red[700]
    }
  },
  sidebar: {
    color: grey[900],
    background: '#FFF',
    header: {
      color: '#FFF',
      background: customBlue[800],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: grey[800],
      background: '#F7F7F7',
      online: {
        background: green[500]
      }
    }
  }
});

const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: 'light'
  },
  sidebar: {
    color: '#FFF',
    background: customBlue[700],
    header: {
      color: '#FFF',
      background: customBlue[800],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: '#FFF',
      background: customBlue[800],
      online: {
        background: '#FFF'
      }
    },
    badge: {
      color: '#000',
      background: '#FFF'
    }
  }
});

const greenVariant = merge(defaultVariant, {
  name: THEMES.GREEN,
  palette: {
    primary: {
      main: green[800],
      contrastText: '#FFF'
    },
    secondary: {
      main: green[500],
      contrastText: '#FFF'
    }
  },
  header: {
    indicator: {
      background: green[600]
    }
  },
  sidebar: {
    color: '#FFF',
    background: green[700],
    header: {
      color: '#FFF',
      background: green[800],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: '#FFF',
      background: green[800],
      online: {
        background: '#FFF'
      }
    },
    badge: {
      color: '#000',
      background: '#FFF'
    }
  }
});

const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: indigo[600],
      contrastText: '#FFF'
    },
    secondary: {
      main: indigo[400],
      contrastText: '#FFF'
    }
  },
  header: {
    indicator: {
      background: indigo[600]
    }
  },
  sidebar: {
    color: '#FFF',
    background: indigo[700],
    header: {
      color: '#FFF',
      background: indigo[800],
      brand: {
        color: '#FFFFFF'
      }
    },
    footer: {
      color: '#FFF',
      background: indigo[800],
      online: {
        background: '#FFF'
      }
    },
    badge: {
      color: '#000',
      background: '#FFF'
    }
  }
});

const variants = [
  defaultVariant,
  designerVariant,
  darkVariant,
  lightVariant,
  blueVariant,
  greenVariant,
  indigoVariant
];

export default variants;
