const paddingTablet = {
  mainContent: '18px 24px',
  aryCard: '21px 18px',
  aryWrapper: '14px 18px',
  aryWrapperY: '18px',
  aryWrapperX: '24px',
  gridRowSpacing: '18px',
  gridSmallRowSpacing: '16px',
  gridColumnSpacing: '10px',
  gridSmallColumnSpacing: '6px',
  aryAlertBottom: '32px'
};

export default paddingTablet;
