import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import Error401 from 'pages/desktop/errors/Error401';
import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthorizationProvider';
import authService from './AuthorizeService';

const getReturnUrl = () => {
  const link = document.createElement('a');
  link.href = '';
  return `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
};

const AuthGuard = (props) => {
  const { path, anonymousAccess, authKeys } = props;
  const auth = useAuth();
  const [{ ready, authenticated, authorized }, setState] = useState({
    ready: false,
    authenticated: false,
    authorized: false
  });

  useEffect(() => {
    (async () => {
      if (anonymousAccess) return;
      const isAuthenticated = await authService.isAuthenticated();
      const hasVersion = await authService.isVersionValid();
      if (!isAuthenticated) {
        // eslint-disable-next-line no-unused-vars
        const returnUrl = getReturnUrl(path);
        await authService.makeAuthorizationRequest();
      }
      setState({
        ready: true,
        authenticated: isAuthenticated,
        authorized:
          hasVersion &&
          (!authKeys || !authKeys?.some((authKey) => !auth[authKey]))
      });
      // IMPT authorize should be false;
    })();
  }, [window.location.pathname]);
  if (!ready && !anonymousAccess) {
    // Should return proper message / loading.
    return <AryLoadingAnimation />;
  }

  if (!authenticated && !anonymousAccess) {
    // Should return proper message / loading.
    return <AryLoadingAnimation />;
  }

  if (!authorized && !anonymousAccess) {
    // Should return proper message / loading.
    return <Error401 />;
  }

  return (
    <React.Suspense fallback={<AryLoadingAnimation />}>
      <props.component />
    </React.Suspense>
  );
};

export default AuthGuard;
