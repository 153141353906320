const paddingDesktop = {
  mainContent: '1.125rem 1.5rem',
  aryCard: '1.5rem',
  aryWrapper: '1.125rem 1.5rem',
  aryWrapperY: '1.125rem',
  aryWrapperX: '1.5rem',
  gridRowSpacing: '1.5rem',
  gridSmallRowSpacing: '1.125rem',
  gridColumnSpacing: '1rem',
  gridSmallColumnSpacing: '0.75rem',
  aryAlertBottom: '2rem'
};

export default paddingDesktop;
