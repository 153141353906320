import createCache from '@emotion/cache';
import {
  CacheProvider,
  ThemeProvider as StyledThemeProvider
} from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import AryLoadingAnimation from 'framework/desktop/AryLoadingAnimation';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  ApiAuthorizationConstants,
  AuthRouteGuard as AuthGuard,
  LoginCallbackHandler,
  authService,
  useAuth
} from 'spec';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import './assets/fontYekanBakh.css';
import createTheme from './framework/base/theme/index';
import useTheme from './framework/base/theme/useTheme';
import './language/translation';
import appRoutes from './routes';

const { ApplicationPaths } = ApiAuthorizationConstants;

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const App = () => {
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const langDirection = i18n.dir();
  const layoutType = useAryLayoutType();
  const appTheme = createTheme(theme, undefined, langDirection, layoutType);
  document.body.dir = langDirection;
  const auth = useAuth();

  const hasVersion = useRef(false);
  const [isVersionChecked, setIsVersionChecked] = useState(false);

  useEffect(() => {
    (async () => {
      hasVersion.current = await authService.isVersionValid();
      if (hasVersion.current !== null) {
        setIsVersionChecked(true);
      }
    })();
  });

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={` ${t('Moneyx')} | %s`}
        defaultTitle={`${t('Moneyx')} | ${t('User Panel')}`}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <StyledThemeProvider theme={appTheme}>
            <DirectionProvider rtl={langDirection === 'rtl'}>
              <LayoutTypeContext.Provider value={layoutType}>
                <Routes>
                  {auth && isVersionChecked ? (
                    <>
                      <Route
                        path="/"
                        element={
                          <Navigate
                            replace
                            to={
                              hasVersion.current
                                ? '/dashboard/user-profile'
                                : '/dashboard/purchase-type'
                            }
                          />
                        }
                      />
                      {lazyRoutes(appRoutes, layoutType)}
                    </>
                  ) : (
                    <Route path="/" element={<AryLoadingAnimation />} />
                  )}
                  <Route
                    exact
                    path={ApplicationPaths.LoginCallback}
                    element={<LoginCallbackHandler />}
                  />

                  <Route path="/*" element={<AryLoadingAnimation />} />
                </Routes>
              </LayoutTypeContext.Provider>
            </DirectionProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
