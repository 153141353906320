import React, { useMemo } from 'react';

import THEMES from './constants';

const initialState = {
  theme: THEMES.DESIGNER,
  setTheme: () => {}
};
const ThemeContext = React.createContext(initialState);

const ThemeProvider = ({ children }) => {
  const initialTheme = () => {
    const storedTheme = localStorage.getItem('theme');

    return storedTheme ? JSON.parse(storedTheme) : THEMES.DEFAULT;
  };

  const [theme, _setTheme] = React.useState(initialTheme());

  const setTheme = (item) => {
    localStorage.setItem('theme', JSON.stringify(item));
    _setTheme(item);
  };

  const themeValue = useMemo(() => ({ theme, setTheme }), []);
  return (
    <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
