import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  const aryauth = localStorage.getItem('aryauth');
  let aid;
  let sub;
  if (aryauth) {
    const parsedAryauth = JSON.parse(aryauth);
    aid = parsedAryauth.profile?.aid;
    sub = parsedAryauth.profile?.sub;
  }

  return {
    AddEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    EmployeeList: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.l']
    ),
    AddOrEditEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    DeleteEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.d']
    ),
    UpdateEmployeeProfile: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    UpdateUserProfile: defaultCheckAccess.call(
      {
        claims
      },
      ['pr.u']
    ),
    VersionTypeApplication: defaultCheckAccess.call(
      {
        claims
      },
      ['vta']
    ),
    ExpirationApplication: defaultCheckAccess.call(
      {
        claims
      },
      ['exa']
    ),
    WhatsappAccess: defaultCheckAccess.call({ claims }, ['w.a']),
    isManager: aid && aid === sub
  };
}

export default GetAuthObject;
