const marginDesktop = {
  pageContentTop: '1.125rem',
  topBackButtonTop: '-0.375rem',
  topBackButtonBottom: '0.125rem',
  wrapperDividerTop: '.625rem',
  wrapperDividerBottom: '1rem',
  sidebarBrandIconRight: '1rem',
  wrapperTableToHeader: '2rem',
  wrapperTableToHeaderInGrid: '0.5rem',
  headerTopInGrid: '0.5rem',
  largeHeaderTopInGrid: '1rem',
  headerTop: '2rem',
  submitButtonTopInGrid: '1.5rem',
  paginationTop: '1.5rem',
  paginationTopInGrid: '0.5rem',
  twoTypographyHorizontal: '0.5rem',
  twoTypographyVertical: '0.5rem',
  headerToContent: '2rem',
  headerToContentInGrid: '0.5rem',
  privateAndSyncCheckboxesLeft: '0.438rem'
};

export default marginDesktop;
