const marginMobile = {
  pageContentTop: '10px',
  paginationTop: '24px',
  tableButtonToBodyInGrid: '6px',
  tableButtonToBody: '20px',
  tableCurrencyToAmount: '6px',
  contentToHeader: '16px',
  contentToHeaderInGrid: '6px',
  headerTopInGrid: '12px',
  largeHeaderTopInGrid: '16px',
  headerTop: '32px',
  drawerSubmitButtonTop: '12px',
  submitButtonToTop: '32px',
  titleToStepper: '18px',
  endIconToButtonLabel: '-6px',
  topBackButtonTop: '-6px',
  topBackButtonBottom: '-20px',
  twoTypographyHorizontal: '4px',
  twoTypographyVertical: '8px',
  privateAndSyncCheckboxesLeft: '7px',
  // ********* not modified ********
  wrapperDividerTop: '10px',
  wrapperDividerBottom: '16px',
  sidebarBrandIconRight: '16px'
  // ********* end not modified ********
};

export default marginMobile;
