import App from 'App';
import ApolloConfigProvider from 'framework/base/utils/ApolloConfigProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthorizationProvider } from 'spec';
import './App.css';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

function onKeyDown(event) {
  if (event.key === 'Enter') {
    event.preventDefault();
    const { form } = event.target;
    if (form) {
      const index = [...form].indexOf(event.target);
      form.elements[index + 2].focus();
    }
  }
}

window.onload = function bootstrapper() {
  document.body.querySelector('div').addEventListener('keydown', onKeyDown);
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthorizationProvider>
        <ApolloConfigProvider>
          <App />
        </ApolloConfigProvider>
      </AuthorizationProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
