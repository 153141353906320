import { configureStore } from '@reduxjs/toolkit';
import systemReducer from './slices/systemSlice';

const store = configureStore({
  reducer: {
    systemReducer
  }
});

export default store;
